<template>
  <el-menu-item :index="handlePath(routeChildren.path)" @click="handleLink">
    <!-- <i v-if="routeChildren.meta && routeChildren.meta.icon" :title="routeChildren.meta.title" :class="['el-icon pr-3', `${routeChildren.meta.icon}`]" ></i>
    <span :title="routeChildren.meta.title">{{ routeChildren.meta.title }}</span> -->
    <icon-item :icon="item.meta.icon||(item.meta&&item.meta.icon)" :title="item.title||item.meta.title" />
    <el-tag
      v-if="routeChildren.meta && routeChildren.meta.badge"
      type="danger"
      effect="dark"
    >
      {{ routeChildren.meta.badge }}
    </el-tag>
  </el-menu-item>
</template>

<script>
  import IconItem from './Item'
  import { isExternal } from '@/utils/validate'
  import {deepClone} from '@/utils/index'
  import path from 'path'

  export default {
    name: 'MenuItem',
    components: { IconItem},
    props: {
      routeChildren: {
        type: Object,
        default() {
          return null
        },
      },
      item: {
        type: Object,
        default() {
          return null
        },
      },
      fullPath: {
        type: String,
        default: '',
      },
    },
    methods: {
      handlePath(routePath) {
        if (isExternal(routePath)) {
          return routePath
        }
        if (isExternal(this.fullPath)) {
          return this.fullPath
        }
        return path.resolve(this.fullPath, routePath)
      },
      handleLink() {
        const routePath =  this.routeChildren.path
        const target = this.routeChildren.meta.target
        if (target === '_blank' || routePath.indexOf('target=_blank') > 0 || this.fullPath.indexOf('target=_blank') > 0) {
          if (isExternal(routePath)) {
            window.open(routePath)
          } else if (isExternal(this.fullPath)) {
            window.open(this.fullPath)
          } else if (
            this.$route.path !== path.resolve(this.fullPath, routePath)
          ) {
            let routeData = this.$router.resolve(
              path.resolve(this.fullPath, routePath)
            )
            window.open(routeData.href)
          }
        } else {
          if (isExternal(routePath)) {
            window.location.href = routePath
          } else if (isExternal(this.fullPath)) {
            window.location.href = this.fullPath
          } else if ( this.$route.path !== path.resolve(this.fullPath, routePath) ) {
            this.$router.push(path.resolve(this.fullPath, routePath))
          }
        }
      },
    },
  }
</script>
